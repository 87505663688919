import dynamic from 'next/dynamic'
import { Controller } from 'react-scrollmagic-r18'
import DefaultLayout from '@/features/common/components/layouts/DefaultLayout'
import { HomePanel } from '@/features/home/components'

const EventModal = dynamic(() => import('@/features/common/components/EventModal'), { ssr: false })
const NoticeModal = dynamic(() => import('@/features/common/components/NoticeModal'), { ssr: false })

function HomePage() {
  return (
    <Controller>
      <DefaultLayout>
        <HomePanel />
        <EventModal />
        <NoticeModal />
      </DefaultLayout>
    </Controller>
  )
}

export default HomePage
